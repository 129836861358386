import * as React from 'react';
import constants from '../constants/application-constants';
interface InvocaPhone {
  phoneNumber: string;
  isFinal: boolean;
}
export const AppContext = React.createContext<{
  phone: InvocaPhone,
  setPhone: React.Dispatch<React.SetStateAction<InvocaPhone>>,
} | null>(null);

const AppProvider: React.FC<{
  children: React.ReactNode | React.ReactNode[]
}> = ({ children }) => {
  const [phone, setPhone] = React.useState({ phoneNumber: constants.DEFAULT_PHONE, isFinal: false });

  return (
    <AppContext.Provider value={{ phone, setPhone }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;